import React, { Component } from 'react'
import Spacer from '../../../Layout/Spacer'
import Section from '../../../Layout/Section'
import ContentFigure from '../../../Layout/ContentFigure'

export class ArdieKitchen extends Component {
  render() {
    return (
      <Section>

        <h1>The<strong>Ardie</strong>Kitchen</h1>

        <Spacer></Spacer>

        <p>TheArdieKitchen was a passion project from my inner foodie. Its beginnings were years in the making. While visiting a friend in Berkeley, her and I needed to come up with a dinner idea before taking me back to the airport. &ldquo;We can just make dinner at my place, &ldquo; she suggested. That&rsquo;s when I learned she could cook. I was so impressed by the meal she put together that I bought myself an array of cookware and cookbooks within the week and practiced making breakfast, lunch, and dinner every day.</p>

        <ContentFigure
            src="images/ardiekitchen/ardiekitchen-logo.svg"
            alt="TheArdieKitchen.com logo"
        ></ContentFigure>

        <p>I became progressively pursuant of great food - especially while traveling. Yelp quickly became a vital part of the process. I began writing reviews, taking photos, and interacting with the community. I wrote over 400 reviews and attended &ldquo;Elite&rdquo; events for years. However, I noticed something qualitative was missing from the community. By nature, Yelp had a lack of objectivity and curiosity in its content. Very few people, if any, asked the &ldquo;who,&rdquo; &ldquo;why,&rdquo; and &ldquo;how&rdquo; behind the experiences they wrote about. An episode of Chef&rsquo;s Table would demonstrate this perspective. This is when I decided to write what I thought to be better content - like Chef&rsquo;s Table. I would investigate the details behind the culinary experiences I loved. I planned to ask questions and discover the history behind the restaurants, chefs, and foods I&rsquo;d come across. All the depth missing from the user-generated food scene.</p>

        <ContentFigure
            src="images/ardiekitchen/ardiekitchen-homepage.jpg"
            alt="TheArdieKitchen.com homepage"
            caption="TheArdieKitchen homepage"
        ></ContentFigure>

        <blockquote className="blockquote">
            <p>To the foodies, gastronomes, epicures, and the culinary-curious.</p>
            <p>I&rsquo;m Ardie. Like many of you, I&rsquo;m a foodie - I love experiencing great food.</p>
            <p>I grew up with my mom&rsquo;s home cooking: fruits, vegetables, and Armenian culture. When I began cooking for myself, I immediately appreciated all the quality and flavor I&rsquo;d been spoiled with. Everything changed. I craved what I didn&rsquo;t know and hadn&rsquo;t experienced about the food all around me – so TheArdieKitchen was born.</p>
            <p>Join me in experiencing food culture in Las Vegas and beyond.</p>
            <footer className="blockquote-footer">Ardie Yorgans, <em>TheArdieKitchen</em></footer>
        </blockquote>


        <Spacer></Spacer>
        <h2>A study in Gelatology</h2>

        <p>Gelatology might be one of the most popular destinations on Yelp, frequently making &ldquo;hottest&rdquo; and &ldquo;top&rdquo; in Las Vegas listings. Desyree Alberganti, Gelatology&rsquo;s face, personality, and general lifeblood, became a friend through my frequent visits. The gelato she makes is often of uncommon flavors but always of the highest quality. Some flavors include olive oil, pear gorgonzola, and foie gras. She was the perfect person to work with because of her passion for gelato and her connection with the community.</p>

        <ContentFigure
            src="images/ardiekitchen/ardiekitchen-gelatology-gelato.jpg"
            alt="Gelatology gelato flavors"
            caption="Gelatology gelato flavors"
        ></ContentFigure>

        <p>I started the process by doing the obvious: asking Desyree if she&rsquo;d be willing to work with me, explaining the idea behind TheArdieKitchen. She happily agreed. We set up two dates. One for taking photos of her gelato and another to interview her. I had experience with photoshoots but none with interviews. While keeping the message of TheArdieKitchen in mind and constantly asking myself, &ldquo;why would I read this?&rdquo; I began to articulate my questions.</p>

        <p>First, starter questions:</p>

        <ul>
            <li>What is gelato? How can I tell the difference between the good and bad stuff?</li>
            <li>What is your gelato?</li>
            <li>What makes Gelatology special? For me, it is human - somehow emotional. There is art in each batch. Most importantly, it&rsquo;s delicious.</li>
            <li>You&rsquo;re known for having both classic and unique flavors. How do you decide what to make next? What&rsquo;s your process? How is the iteration?</li>
            <li>Gelatology is a business yet clearly a passion, too. How do you bring the two together?</li>
        </ul>

        <p>With the simpler questions out of the way, I wanted to dive into her history.</p>

        <ul>
            <li>What was your childhood like? What were your influences?</li>
            <li>How was food part of your life growing up? How did gelato come into your life? Was there a turning point or defining moment?</li>
            <li>Does your childhood have a flavor? Which flavors are special to you?</li>
            <li>Tell me about your kitchen and dinner table.</li>
            <li>Do you dream of gelato?</li>
        </ul>

        <p>Lastly, I wanted to understand more about her mindset at an abstract level.</p>

        <ul>
            <li>An artist often has a medium of expression. Is gelato your canvas?</li>
            <li>Role models - who and why?</li>
            <li>What do you think of the foodie culture in Vegas?</li>
            <li>What is success? Is Gelatology successful?</li>
            <li>Coming from Art of Flavors and now on to Gelatology, what have you learned?</li>
            <li>What do you want your family and children to learn from you or in general? What&rsquo;s the message in you?</li>
        </ul>

        <p>The interview was wonderful. She gave me the gift of her time and honesty from which I derived who she was and why she does what she does. The reasons for her success became obvious.</p>

        <ContentFigure
            src="images/ardiekitchen/ardiekitchen-gelatology-hotchocolate.jpg"
            alt="Gelatology gelato-based hot chocolate"
            caption="Gelatology gelato-based hot chocolate"
        ></ContentFigure>

        <blockquote className="blockquote">
            <p>Gelato is not what Desyree Alberganti does best. It&rsquo;s the art at her exhibition before she walks in. All eyes turn to her not because she&rsquo;s a celebrity but because she&rsquo;s like family. Building family is what Desyree does best.</p>
            <footer className="blockquote-footer"><em>The<strong>Ardie</strong>Kitchen</em></footer>
        </blockquote>

        <ContentFigure
            src="images/ardiekitchen/ardiekitchen-gelatology-cup.jpg"
            alt="Gelatology gelato flavors in a cup"
            caption="Gelatology gelato flavors in a cup"
        ></ContentFigure>

        <blockquote className="blockquote">
            <p>It&rsquo;s important from the beginning to the end to always be there and be part of that [experience]. I have to be there to guide the customer. They&rsquo;ll never ask to try something. You have to give it to them, mention it, let them know... Pear gorgonzola, for example. Everyone loves it. They say it tastes like cheese… because it has cheese!</p>
            <footer className="blockquote-footer">Desyree Alberganti, <em>Gelatology</em></footer>
        </blockquote>

        <ContentFigure
            src="images/ardiekitchen/ardiekitchen-gelatology-desyree.jpg"
            alt="Desyree Alberganti"
            caption="Gelatology's Desyree Alberganti"
        ></ContentFigure>

      </Section>
    )
  }
}

export default ArdieKitchen
