import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Section from '../../../Layout/Section'
import ContentFigure from '../../../Layout/ContentFigure'
import Spacer from '../../../Layout/Spacer';

export class InstallmentLoans extends Component {

  render() {
    return (
      <Section>

        <h1>InstallmentLoans.com&reg;</h1>

        <Spacer></Spacer>

        <p><em>RevEngine</em>, a massive lead generator in the short term loan space, experienced an involuntary shift in focus. The short term and &ldquo;payday&rdquo; loan industries were under heavy scrutiny and regulation. Installment loans quickly became the industry favorite because they had similar dollar amounts, more flexible payback time, and weren&rsquo;t in hot water with the government. <em>RevEngine</em>, with <em>InstallmentLoans.com&reg;</em> in hand, was ready to make the jump to a new product with a new consumer. This is where I joined the team.</p>

        <Spacer></Spacer>

        <h2>A lay of the land</h2>

        <ContentFigure inline className="text-center" src="images/installmentloans/installmentloans-logo.svg" alt="InstallmentLoans.com logo"></ContentFigure>

        <p><em>InstallmentLoans.com&reg;</em> was a clean slate with the exception of a logo and legal limitations on data collection, wording and disclaimers. An array of questions immediately came to mind:</p>

        <ul>
            <li>What is an installment loans? No, really, isn&rsquo;t it just a loan?</li>
            <li>Who was the installment loan designed for?</li>
            <li>Why would a consumer consider an installment loan? What problem(s) did it solve?</li>
            <li>Who was already offering installment loans and were they successful?</li>
            <li>How could we differentiate <em>InstallmentLoans.com&reg;</em> from the competition?</li>
            <li>What knowledge did <em>RevEngine</em> have on the short term loan industry that could help design a relevant platform for installment loans?</li>
            <li>What mistakes did the short term loan industry make that we could avoid making with <em>InstallmentLoans.com&reg;</em>?</li>
        </ul>

        <p>First things first, let&rsquo;s define an installment loan:</p>

        <blockquote>
            <p>An installment loan is a type of loan repaid over time with a set number of scheduled payments that include principal and interest. Installment loan schedules are characterized by monthly, semi-monthly or bi-weekly payments based on the terms of the loan and can be used for any purpose.</p>
            <footer><em>InstallmentLoans.com&reg;</em></footer>
        </blockquote>

        <p>The term &ldquo;installment loan&rdquo; refers to payments being made in a set number of installments. &ldquo;Personal loan&rdquo; is used interchangeably. Without intended purpose, collateral, or maximum dollar amount required, it became evident that installment loans were meant for general use by anyone who qualifies with a lender. There was no single problem these loans were intended to solve. However, they did offer simplification where an array of loan products already existed.</p>

        <ContentFigure
            src="images/installmentloans/installmentloans-competition-personalloans.jpg"
            alt="PersonalLoans homepage"
            caption="PersonalLoans.com homepage"
        ></ContentFigure>

        <ContentFigure
            src="images/installmentloans/installmentloans-competition-avant.jpg"
            alt="Avant homepage"
            caption="Avant.com homepage"
        ></ContentFigure>

        <ContentFigure
            src="images/installmentloans/installmentloans-competition-lendingclub.jpg"
            alt="LendingClub homepage"
            caption="LendingClub.com homepage"
        ></ContentFigure>

        <p>A variety of brick-and-mortar banks and online lenders offered installment loans in varying loan amounts with no obvious success or failure. &ldquo;This is messy,&rdquo; I thought. It felt the way the movie industry did when Blu-ray and HD-DVD were on retailer shelves together. Companies offered installment loans alongside traditional loans, making the selection process tough without clear differentiation and guidance.</p>
        <p>Using the experience and data <em>RevEngine</em> already possessed in combination with my own research, we discovered the target demographics for installment loans were similar to those of short term and payday loans.</p>

        <ul>
            <li>Men and women almost equally used these loans (slightly more women)</li>
            <li>Ages between mid 20&rsquo;s to mid 40&rsquo;s</li>
            <li>Incomes ranging $25,000 and $55,000</li>
            <li>Reasons, although not required, were often debt consolidation and paying off higher interest credit cards. Conversely, payday loans were often used for daily expenses.</li>
        </ul>


        <Spacer></Spacer>

        <h2>Learning from history</h2>

        <p>This brought me to researching the mistakes the short term and payday loan industry had made over time. Some points were more obvious than others.</p>

        <ul>
            <li>Short term loans came with a negative connotation the industry did nothing to combat.</li>
            <li>Advertising and marketing materials were consistently abusive.</li>
            <li>Rates were often excessively high, into triple digits.</li>
            <li>Paperwork was difficult to understand.</li>
            <li>The business model for many lenders was unsustainable on the surface. It relied on a consumer to default at least three times before becoming profitable on fees and skyrocketing interest rates to offset operating costs. Loan defaults per consumer averaged around 7. <a href="http://www.pewtrusts.org/en/research-and-analysis/collections/2014/12/payday-lending-in-america" rel="nofollow noreferrer noopener" target="_blank">This is the definition of predatory</a>.</li>
        </ul>

        <p>Generally most companies in this space decisioned only on business goals rather than aligning them with consumer needs. &ldquo;Why don&rsquo;t these consumers just find another way to get the money?&rdquo; was the lingering question on my mind. A little research revealed many weren&rsquo;t aware of alternative products or were simply too embarrassed to ask family and friends.</p>


        <Spacer></Spacer>

        <h2>Finding direction</h2>

        <p>Let&rsquo;s rewind. <em>RevEngine</em> is not a lender. It&rsquo;s a lead generator. How could it differentiate itself with <em>InstallmentLoans.com&reg;</em> in a space becoming as saturated as the short term loan space? Through everything I learned, it became clear: <strong>empower the consumer to make smarter decisions</strong>. Instead of pandering to, misleading, or harassing a consumer - why not educate them and earn trust in an industry typically interested in simply making a quick buck?</p>

        <p><em>InstallmentLoans.com&reg;</em> had a huge task at hand. It needed to:</p>

        <ul>
            <li>Clearly differentiate an installment loan from other loan types</li>
            <li>Contain quality, relevant, easily-digested content</li>
            <li>Guide the consumer through a modern, friendly form process</li>
        </ul>

        <p>There was a second challenge to build <em>InstallmentLoans.com&reg;</em>: technology. While the company had frameworks available to host lead generation sites and their respective form flows, they desperately needed modernization.</p>


        <Spacer></Spacer>
        
        <h2>Let&rsquo;s get moving</h2>

        <p>We quickly specified and began to build a newer version of the framework, short of a complete replacement (check out the next project, "Building a Framework", for that), to give ourselves the tools to do the job right. Every addition, update, and change we made needed to address a series of problem areas dealing with the user experience and the development process. <strong>Everything needed to be extensible and scalable</strong>. In addition, we wanted any future developers to quickly get a grasp on how the system was built and operated - it needed to be elegant.</p>

        <ul>
            <li>We integrated Bootstrap 3 into the framework. To make this flexible, any site using the framework was configured to allow switching Bootstrap components &ldquo;on&rdquo; or &ldquo;off,&rdquo; keeping bloat down. Loading order and file structure was set to allow proper inheritance and overriding of various settings.</li>
            <li>We wrote an array of custom JavaScript functions generic enough to be used on any site in the framework, cutting down the work required to do common tasks involving the user experience or the form process.</li>
            <li>We designed a custom field validation system for a polished, friendly user experience unheard of in the industry. Why engulf a consumer in a &ldquo;sea of red&rdquo; error messages when we can help move them along with visuals and language that are encouraging and informative? I didn&rsquo;t want to see &ldquo;this field is required&rdquo; ever again.</li>
            <li>We abstracted out text content into &ldquo;translation files.&rdquo; This made it reusable - especially handy for frequently repeated information like the site names and common button text, for example.</li>
        </ul>

        <p>With framework updates in tow, we tackled content and design. A check around the competition in the installment space revealed the industry did understand that consumers wanted a more modern, trustworthy, and less &ldquo;cash fans in your face&rdquo; experience. Where they still fell flat was content. This would be our leading edge. We knew the pain points consumers faced and came up with a list of pages to address them. We made it our mission to write content meant to be read - not for SEO or legalities only.</p>

        <ul>
            <li>A home page about installment loans with primer information</li>
            <li>A &ldquo;how it works&rdquo; page to describe the application process we delivered</li>
            <li>Frequently asked questions</li>
            <li>Debt management and consolidation information</li>
            <li>Money management information</li>
            <li>Information on how to build credit</li>
        </ul>

        <p>Weeks of writing, designing, and iterating on both had passed. Pages of information were revised, and revised, and revised until we felt we had a collection of content easily understood and useful to our consumers. We had <em>InstallmentLoans.com&reg;</em>.</p>

        <ContentFigure
            src="images/installmentloans/installmentloans-homepage.jpg"
            alt="InstallmentLoans.com homepage"
            caption="InstallmentLoans.com&reg; homepage"
        ></ContentFigure>

        <ContentFigure
            src="images/installmentloans/installmentloans-debtmanagement.jpg"
            alt="InstallmentLoans.com debt management resources"
            caption="InstallmentLoans.com&reg; debt management resources"
        ></ContentFigure>

        <ContentFigure
            src="images/installmentloans/installmentloans-moneymanagement.jpg"
            alt="InstallmentLoans.com money management resources"
            caption="InstallmentLoans.com&reg; money management resources"
        ></ContentFigure>

        <ContentFigure
            src="images/installmentloans/installmentloans-employmentinfo.jpg"
            alt="InstallmentLoans.com employment information page"
            caption="InstallmentLoans.com&reg; employment information form page"
        ></ContentFigure>

      </Section>
    )
  }
}

InstallmentLoans.propTypes = {
    transitionHandler: PropTypes.func
  };

export default InstallmentLoans
