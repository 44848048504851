import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const spacerSizeBase = 30, spacerSizes = [];
spacerSizes["base"] = spacerSizeBase + 'px';
spacerSizes["xs"] = (spacerSizeBase / 3) + 'px';
spacerSizes["sm"] = (spacerSizeBase / 2) + 'px';
spacerSizes["lg"] = (spacerSizeBase * 2) + 'px';

const SpacerBlock = styled.div`
  height: ${ props => props.size }
`;

export class Spacer extends Component {
  constructor (props) {
    super(props);

    let size;
    if(this.props.xs)size = spacerSizes["xs"];
    else if(this.props.sm)size = spacerSizes["sm"];
    else if(this.props.lg)size = spacerSizes["lg"];
    else size = spacerSizes["base"];

    this.state = {
      size: size
    };
  }
  render() {
    return (
      <SpacerBlock size={ this.state.size }></SpacerBlock>
    )
  }
}

Spacer.propTypes = {
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  lg: PropTypes.bool
};

export default Spacer
