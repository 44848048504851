import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Figure from 'react-bootstrap/Figure'

export class ContentFigure extends Component {
  render() {
    return (
      <div className="region rounded text-center">
        <Figure>
          <Figure.Image
            width={ this.props.width }
            height={ this.props.height }
            alt={ this.props.alt }
            src={ this.props.src }
            className="img-fluid"
          />
          { this.props.caption ? <Figure.Caption>{ this.props.caption }</Figure.Caption> : null }
        </Figure>
      </div>
    )
  }
}

ContentFigure.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  caption: PropTypes.string
};

ContentFigure.defaultProps = {
  width: "100%",
  height: "100%",
  alt: null,
  src: null,
  caption: null,
}

export default ContentFigure
