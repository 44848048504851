import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'
import ScrollToTop from './Components/ScrollToTop'
import { CSSTransition } from 'react-transition-group'
import Section from './Components/Layout/Section'
import Spacer from './Components/Layout/Spacer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ScrollUpButton from "react-scroll-up-button"

import { Home as PageHome } from './Components/Pages/Home'
import { InstallmentLoans as PageInstallmentLoans } from './Components/Pages/Projects/Professional/InstallmentLoans'
import { Framework as PageFramework } from './Components/Pages/Projects/Professional/Framework'
import { MoneyMutual as PageMoneyMutal } from './Components/Pages/Projects/Professional/MoneyMutual'
import { ArdieKitchen as PageArdieKitchen } from './Components/Pages/Projects/Personal/ArdieKitchen'
import { Yeres as PageYeres } from './Components/Pages/Projects/Personal/Yeres'
import { Photography as PagePhotography } from './Components/Pages/Projects/Personal/Photography'

class YoArdie extends Component {

  constructor(props){
    super(props);

    this.state = {
      isHomepage: false
    }

    this.animationTime = 500;

    this.toHome = this.toHome.bind(this);
  }

  toHome(isHome) {
    this.setState({
      isHomepage: isHome
    });
  }
  

  render() {
    
    return (
      <Router>

        <ScrollToTop>

          <Container>
            <Row>

              <Col xs={{ offset: 1, span: 10 }} sm={{ offset: 0, span: 12 }}>

                <Section>

                  <div id="brand-box">

                    <CSSTransition in={ !this.state.isHomepage } classNames="animation-logo-resize" timeout={ this.animationTime } appear={ true }>
                      <div>
                        <Link to="/">
                          <img className="logo" src={ process.env.PUBLIC_URL + 'images/logo-ardie-yorgans.svg' } alt="Ardie Yorgans" />
                        </Link>
                      </div>
                    </CSSTransition>

                    <Route exact path="/" render={props => ( <h1 className="d-none">Ardie Yorgans</h1> )} />

                    <CSSTransition in={ !this.state.isHomepage } classNames="animation-collapse" timeout={ this.animationTime } appear={ true }>
                      <div className="overflow-hidden">
                        <Spacer></Spacer>
                        <p className="h4">Designer, developer, creator</p>
                        <p className="h4">Crafting great experiences on the internet&nbsp;since&nbsp;2001.</p>
                      </div>
                    </CSSTransition>

                  </div>

                  <Spacer></Spacer>

                  <Container>
                    <Row>

                      <Col xl={{ offset: 1, span: 5 }} md={{ offset: 0, span: 6 }} sm={{ offset: 1, span: 10 }} xs={12}>

                        <a href="https://www.linkedin.com/in/ardieyorgans" target="_blank" className="btn btn-lg btn-success btn-block" rel="noopener noreferrer">Connect on LinkedIn</a>

                        <Spacer sm className="d-block d-sm-none"></Spacer>
                        
                      </Col>

                      <Col xl={{ offset: 0, span: 5 }}  md={{ offset: 0, span: 6 }} sm={{ offset: 1, span: 10 }} xs={12}>
                        
                        <a href="mailto:hello@yoardie.com" className="btn btn-lg btn-success btn-block">Email at hello@yoardie.com</a>

                      </Col>

                    </Row>
                  </Container>

                </Section>

                <CSSTransition in={ !this.state.isHomepage } classNames="animation-collapse" timeout={ this.animationTime }>
                  <div>
                    <Route exact path="/" render={ (props) => <PageHome {...props} toHome={ this.toHome } /> }></Route>
                  </div>
                </CSSTransition>


              </Col>

            </Row>
          </Container>

          <CSSTransition in={ this.state.isHomepage } classNames="animation-collapse" timeout={ this.animationTime } appear={ true }>

            <Container>
              <Row>
                <Col xs={{ offset: 0, span: 12 }} lg={{ offset: 1, span: 10 }} xl={{ offset: 2, span: 8 }}>

                  <Switch>

                    <Route path="/installmentloans-brand-and-experience" component={ PageInstallmentLoans } />
                    <Route path="/building-a-framework" component={ PageFramework } />
                    <Route path="/moneymutual-rebuilt" component={ PageMoneyMutal } />

                    <Route path="/the-ardie-kitchen" component={ PageArdieKitchen } />
                    <Route path="/yeres-messenger" component={ PageYeres } />
                    <Route path="/photography" component={ PagePhotography } />

                  </Switch>

                </Col>
              </Row>
            </Container>

          </CSSTransition>

          <ScrollUpButton
            ContainerClassName="scroll-to-top-container"
            TransitionClassName="scroll-to-top-transition"
          >
            <Button variant="outline-primary"><span className="icono-arrow3-up" ref={ input => this.btnBackToTop = input }></span></Button>
          </ScrollUpButton>

        </ScrollToTop>

      </Router>
    );
  }
}

export default YoArdie;
