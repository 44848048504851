import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Spacer from './Spacer'

export class Section extends Component {
  render() {
    return (
      <section className="section">
        { this.props.headerText ? <h2 className="text-center h1"> { this.props.headerText }<Spacer></Spacer></h2> : null }
        { this.props.children }
      </section>
    )
  }
}

Section.propTypes = {
  headerText: PropTypes.string
};

export default Section