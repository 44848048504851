import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

export class ProjectCard extends Component {
  constructor(props) {
    super(props);

    let { title = "", description = "", coverUrl = "", link = "" } = this.props.cardData || {};
    
    this.state = {
      title,
      description,
      coverUrl,
      link
    };
  }

  render() {
    return (
      <Card className="shadow">
        <Link to={ this.state.link }>
          <Card.Img variant="top" src={ process.env.PUBLIC_URL + '/' + this.state.coverUrl } />
        </Link>
        <Card.Body>
          <Card.Title className="text-center">{ this.state.title }</Card.Title>
          <Card.Text>{ this.state.description }</Card.Text>
        </Card.Body>
        <Card.Footer className="bg-transparent">
          <Link to={ this.state.link }>
            <Button variant="primary" className="btn-block">Read more</Button>
          </Link>
        </Card.Footer>
      </Card>
    )
  }
}

ProjectCard.propTypes = {
  cardData: PropTypes.object.isRequired,
};

ProjectCard.defaultProps = {
  cardData: {}
}

export default ProjectCard
