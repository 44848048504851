const ProjectsProfessional = [
  {
    id: 0,
    title: "InstallmentLoans.com",
    description: "Designing, developing, and editing a new and powerful consumer lending brand. Focus on research and development of a smooth, relevant user experience.",
    coverUrl: "images/cover-installmentloans.jpg",
    link: "/installmentloans-brand-and-experience"
  },
  {
    id: 1,
    title: "Building a Framework",
    description: "Building a flexible, extensible, and modern front-end framework supporting over 15 revenue-generating, high-traffic web properties.",
    coverUrl: "images/cover-framework.jpg",
    link: "/building-a-framework"
  },
  {
    id: 2,
    title: "MoneyMutual.com",
    description: "Rebuilding an industry-leading short-term lending lead generator. Optimized for browser performance, user experience, and revenue-based metrics.",
    coverUrl: "images/cover-moneymutual.jpg",
    link: "/moneymutual-rebuilt"
  }
];

export { ProjectsProfessional };

const ProjectsPersonal = [
  {
    id: 0,
    title: "TheArdieKitchen",
    description: "A passion project using storytelling to build relationships between those who love food and those who make it.",
    coverUrl: "images/cover-ardiekitchen.jpg",
    link: "/the-ardie-kitchen"
  },
  {
    id: 1,
    title: "Yeres Messenger",
    description: "A startup story, born in competition, aimed at improving text-based communications by leveraging body language.",
    coverUrl: "images/cover-yeres.jpg",
    link: "/yeres-messenger"
  },
  {
    id: 2,
    title: "Photography and storytelling",
    description: "An journey into learning to distill memories, visually tell stories, and inspire others to do the same.",
    coverUrl: "images/cover-photography.jpg",
    link: "/photography"
  }
];

export { ProjectsPersonal };