import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Section from '../Layout/Section'
import ProjectCard from '../Layout/ProjectCard'
import Spacer from '../Layout/Spacer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ProjectsPersonal, ProjectsProfessional } from '../../Data/ProjectCardData'

export class Home extends Component {

  constructor(props) {
    super(props);

    this.buildCards = this.buildCards.bind(this);
  }

  buildCards(cards) {
    return cards.map((card, index) => (
      <ProjectCard cardData={ card } key={ index }></ProjectCard>
    ));
  }

  componentWillMount() {
    this.props.toHome(true);
  }

  componentWillUnmount() {
    this.props.toHome(false);
  }

  render() {
    return (
      <>
        <Section name="professional" headerText="Professional Projects">

          <div className="card-deck-mod">
            { this.buildCards(ProjectsProfessional) }
          </div>

          </Section>

          <Section name="personal" headerText="Personal Projects">

          <div className="card-deck-mod">
            { this.buildCards(ProjectsPersonal) }
          </div>

        </Section>

        <Section name="more" headerText="Want to know more?">
          <Container className="text-center">

            <Row>
              <Col md={4} xs={12}>
                <p className="h4">My love for photography means I always keep a camera&nbsp;close.</p>
                <Spacer sm className="d-block d-sm-none"></Spacer>
              </Col>

              <Col md={4} xs={12}>
                <p className="h4">Wrenching on and racing cars has been my hobby for&nbsp;years.</p>
                <Spacer sm className="d-block d-sm-none"></Spacer>
              </Col>

              <Col md={4} xs={12}>
                <p className="h4">I cook and bake often, especially with&nbsp;friends.</p>
              </Col>

            </Row>

            <Spacer sm></Spacer>

            <Row>
              <Col md={4} xs={12}>
                <p className="h4">I love to travel abroad, explore nature, and meet new friends.</p>
                <Spacer sm className="d-block d-sm-none"></Spacer>
              </Col>

              <Col md={4} xs={12}>
                <p className="h4">Gratitude and empathy are vital to everything&nbsp;I&nbsp;do.</p>
                <Spacer sm className="d-block d-sm-none"></Spacer>
              </Col>

              <Col md={4} xs={12}>
                <p className="h4">I can pick a great&nbsp;watermelon.</p>
              </Col>

            </Row>
            
          </Container>
        </Section>
      </>
    )
  }
}

ProjectCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  toHome: PropTypes.func
};

ProjectCard.defaultProps = {
  cardData: []
}

export default Home
