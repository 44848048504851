import React, { Component } from 'react'
import Spacer from '../../../Layout/Spacer'
import Section from '../../../Layout/Section'
import ContentFigure from '../../../Layout/ContentFigure'

export class Yeres extends Component {
  render() {
    return (
      <Section>

        <h1>Yeres Messenger</h1>

        <Spacer></Spacer>

        <h2>Startup Weekend</h2>

        <p>I ran into a friend from high school who was heavily involved in the up-and-coming <em><a href="https://vegastech.com/" target="_blank" rel="noreferrer noopener nofollow">VegasTech</a></em> startup community in downtown Las Vegas. It was my first exposure to startup culture as I began attending the meetups. It was at a social mixer that I learned about a hackathon coming up held by Startup Weekend. It was the first of its kind in Las Vegas and I was already sold on the idea.</p>

        <p>Held at the <em>El Cortez Hotel</em>, a massive room was reserved for the hackathon. I knew nearly nobody there. When it came time for pitching ideas to the crowd, I was ready. I got on stage and gave my &ldquo;elevator pitch&rdquo; on an app designed to introduce body language into text messaging. A messaging platform that would use a person&rsquo;s facial expressions to add context to conversation in ways typed language could not.</p>


        <Spacer></Spacer>

        <h2>The problem</h2>

        <p>Every product needs to address a problem. Mine was aimed at solving the communication gaps present in the most popular electronic communication medium ever - text messaging. I researched how people communicate and some technology usage statistics first. Here&rsquo;s what I learned.</p>

        <ul>
            <li>
                Communications based on text such as SMS and email only utilize 7% of human expression to convey a message. This leaves much to interpretation by the receiver.

                <ul>
                    <li>7% of all communication is simply words. 50%+ is body language</li>
                    <li>18-24 year olds send and receive ~4,000 messages per month</li>
                    <li>25-34 year olds send and receive ~2,300 messages per month</li>
                    <li>35-44 year olds send and receive ~1,500 messages per month</li>
                    <li>Text messages and emails have ~50% misinterpretation rate</li>
                </ul>
            </li>
            <li>Body language and tone are lost, dehumanizing the communication</li>
            <li>Meaningful, clear relationships are often prevented in the present and future</li>
            <li>Emotions are generally underserved. Technology has generally detracted from expression in communications instead of enabling it &mdash; case of quantity and convenience over quality.</li>
        </ul>

        <p>Think of all the times anyone has had a misinterpreted text message. The lost moments of sarcasm. Mood left in question. It&rsquo;s all because communication is fundamentally non-verbal. There was clear opportunity for improvement to the way people communicate over short messages.</p>

        <ul>
            <li>A likely reduction in miscommunications by decreasing the need for interpretation</li>
            <li>Allow for stronger, clearer relationships because of better communication</li>
            <li>Emotion is conveyed because body language is reintroduced</li>
            <li>Intent of a message is clearer</li>
        </ul>

        <blockquote className="blockquote text-center">
            <p className="mb-0">&ldquo;You saved sarcasm&rdquo;</p>
            <footer className="blockquote-footer">Anonymous hackathon attendee</footer>
        </blockquote>


        <Spacer></Spacer>

        <h2>The team</h2>

        <p>It&rsquo;s tough to make cold contacts, especially with other developers. It&rsquo;s even tougher to do so in an environment where developers are hard to find yet required to get a project off the ground. We had just a few minutes to find others to work with in the room and I felt out of luck - nearly out of time and nobody left unclaimed. However, in the far reaches of the room were two developers looking for a team: Jason and James. I reminded them of the idea I pitched, they got on board, and we got to developing.</p>

        <p>I took a completely different role than my traditional design and development position: I led the team. I spoke to the crowd, networked, marketed, researched, and guided my team to the direction we all felt was right. James developed our application&rsquo;s Android base, while Jason developed the Ruby-based platform we would run our messages through.</p>

        <ContentFigure
            src="images/yeres/yeres-sketches.jpg"
            alt="Yeres Messenger initial sketches"
            caption="Yeres Messenger initial sketches"
        ></ContentFigure>


        <Spacer></Spacer>

        <h2>Finish line</h2>

        <p>Like most hackathons, this one lasted all day, all night, for a couple of days. I planned my presentation and rehearsed, refining marketing materials and value propositions. Jason and James developed the platform to come up with a working demo where someone could load our app, store an array of their expressions, and use them in conversation. A barebones product - but that&rsquo;s the point.</p>

        <p>The event was coming to a close. The panel of judges were ready. After nearly 20 practice sessions outside the main hall, I was ready to give our presentation. When it came our time, it went smoothly, quickly, and drew an overall positive reception. Judges were quiet. We thought nothing of it. There were larger groups with more diverse talent working on a bigger scale, after all. We made a good effort.</p>

        <ContentFigure
            src="images/yeres/yeres-presentation-slide1.jpg"
            alt="Yeres Messenger presentation slide"
            caption="Yeres Messenger presentation slide"
        ></ContentFigure>

        <ContentFigure
            src="images/yeres/yeres-presentation-slide2.jpg"
            alt="Yeres Messenger presentation slide"
            caption="Yeres Messenger presentation slide"
        ></ContentFigure>

        <p>After presentations were done and judges had time to discuss, announcements of winners began. That&rsquo;s when we learned we were wrong. We won first prize. Judges praised the idea as original and with great potential - specifically in Asian markets.</p>

        <p>In the coming months, we gave numerous presentations within the startup community. I entered a variety of hackathons as a judge and coach more often than not. Unfortunately, our project did not end up being developed any further.</p>

        <Spacer></Spacer>
        
        <h2>Takeaways</h2>

        <p>Despite never completing and iterating on the app, I learned a number of valuable lessons from the experience and everything it led to.</p>

        <ul>
            <li>Startup culture is not for everyone. It requires hard work, humility, dedication, and constant self-assessment and iteration.</li>
            <li>Ideas, even original ones, are essentially worthless without proper execution. Execution comes in a multitude of flavors.</li>
            <li>I have a love and a hidden talent for public speaking and presentation along with leadership qualities and influence over others.</li>
        </ul>

      </Section>
    )
  }
}

export default Yeres
