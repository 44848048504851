import React, { Component } from 'react'
import Section from '../../../Layout/Section'
import Spacer from '../../../Layout/Spacer'
import ContentFigure from '../../../Layout/ContentFigure'

export class MoneyMutual extends Component {
  render() {
    return (
      <Section>

        <h1>MoneyMutual.com&reg;</h1>

        <Spacer></Spacer>

        <p>If you watch daytime television, you&rsquo;ve probably seen Montel Williams as a spokesperson for a payday loan website: <em>MoneyMutual.com&reg;</em>. It&rsquo;s one of the biggest names in the industry and it came time for us to rebuild it from scratch. No pressure.</p>

        <ContentFigure
            src="images/moneymutual/moneymutual-logo.png"
            alt="MoneyMutual.com logo"
        ></ContentFigure>

        <p>MoneyMutual was a solid product on a solid platform, but it wasn&rsquo;t without faults. As with any site, its code was becoming dated. From a front-end perspective, the site didn&rsquo;t meet many needs a modern site demanded. At the same time, we were under strict instructions not to fundamentally change the site&rsquo;s look and feel. Basically, build a classic-looking Porsche using new Porsche underpinnings. After a bit of analysis of the current build, I found many points of improvement to address.</p>

        <ul>
            <li>The site wasn&rsquo;t responsive. Content was constantly obscured. The development process included writing separate mobile and desktop site code, too.</li>
            <li>CSS was all over the place and full of redundancy and inconsistencies. While this is common to aging sites, it&rsquo;s a point to improve upon.</li>
            <li>Form fields lacked consistency. One field may be a certain width and height, while another would be completely different. Alignments were mismatched, too.</li>
            <li>JavaScript was used heavily for simple effects where CSS could do the same with greater compatibility.</li>
        </ul>

        <p>A number of core issues were solved by the new framework we developed (the previous article). This included Bootstrap <abbr title="Leaner CSS" className="initialism">LESS</abbr> and JavaScript, common page actions and tools and abstracted common resources, and finally some team training to get everyone up to speed on modern web standards and expectations.</p>

        <p>Right off the bat, <em>MoneyMutual.com&reg;</em> could be built responsively on Bootstrap&rsquo;s grid system. The challenge would only be in converting a layout designed to be static to a responsive one without fundamentally altering its look and feel - as requested. In the end, we were able to fit the design into a responsive framework and demonstrate its strengths to the business. In doing so, we earned flex in the design constraints going forward. If we were going to reap the benefits of responsive design, the outgoing mobile experience needed to be scrapped and rebuilt as an integral part of the desktop experience.</p>

        <p>With the integration of Bootstrap&rsquo;s <abbr title="Leaner CSS" className="initialism">LESS</abbr> libraries and the availability of our own abstracted <abbr title="Leaner CSS" className="initialism">LESS</abbr> resources, we were able to modularize and consolidate a number of styles. Colors, sizes, and images could be used as variables. Everything was re-written. Redundancies were removed. Even with the team&rsquo;s relatively new knowledge and experience, the efficiency and maintainability gained was monumental.</p>

        <ContentFigure
            src="images/framework/framework-moneymutual-validation.jpg"
            alt="MoneyMutual.com field validation states"
            caption="MoneyMutual.com&reg; field validation states"
        ></ContentFigure>

        <p>Inconsistent form fields. There&rsquo;s simply no reason these should exist. The new framework would be able to quickly correct for this by default. Since the back end framework was built in Symfony, this allowed for &ldquo;form themes.&rdquo; These themes dictated how each and every field would be rendered. If set up correctly, a developer within the team would only need to tell Symfony which field to render and it would handle the rest.</p>

        <p><em>MoneyMutual.com&reg;</em> developed with one more form field design element - whole rows, which highlighted themselves on mouse-over, would be dedicated to each field. Field labels were also positioned within the fields themselves and raised up to their default, and logical, position when the field gained focus.This behavior was inconsistent and imprecise - reasoning aside. Both of these functions were moved from JavaScript implementations to CSS3-based animations. In creating CSS3 keyframe-based animations, what the animations did was clearly defined by their naming convention. This was a huge benefit over the near-cryptic JavaScript implementations.</p>

        <ContentFigure
            src="images/moneymutual/moneymutual-home.jpg"
            alt="MoneyMutual.com homepage"
            caption="MoneyMutual.com&reg; homepage"
        ></ContentFigure>

        <ContentFigure
            src="images/moneymutual/moneymutual-employmentinfo.jpg"
            alt="MoneyMutual.com employment information form"
            caption="MoneyMutual.com&reg; employment information form"
        ></ContentFigure>

        <ContentFigure
            src="images/moneymutual/moneymutual-bankinginfo.jpg"
            alt="MoneyMutual.com deposit information form"
            caption="MoneyMutual.com&reg; deposit information form"
        ></ContentFigure>

      </Section>
    )
  }
}

export default MoneyMutual
