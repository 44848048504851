import React, { Component } from 'react'
import Section from '../../../Layout/Section'
import ContentFigure from '../../../Layout/ContentFigure'
import Spacer from '../../../Layout/Spacer';

export class Framework extends Component {
  render() {
    return (
      <Section>

        <h1>Building a framework</h1>

        <Spacer></Spacer>

        <p>In the previous project, <em>InstallmentLoans.com&reg;</em>, I was part of a team that needed a better framework to design and develop with. This framework needed to be extensible, scalable, configurable, rapid, and elegant. However, it was a stopgap until a complete replacement was developed. With a bigger project, <em>MoneyMutual.com&reg;</em>, looming it was time to get started. Why did we need it, though? What process did we follow to make the choices we did?</p>


        <Spacer></Spacer>

        <h2>Look and feel</h2>

        <p><em>Partner Weekly</em>, another lead generation company hosting an array of sites, didn&rsquo;t have a solid foundation for building sites from a front end standpoint. Resources were scattered, undocumented, and dated. Every hosted site looked, felt, and operated differently. From a development and maintenance standpoint, we needed flexible abstraction and standardization.</p>

        <p>An effective front end framework needed to achieve a few key points:</p>

        <ul>
            <li>It needed to be used simply and elegantly with good documentation and uniformity.</li>
            <li>It needed to be extensible, scalable, and configurable to be a dependency for all sites yet remain fully customizable per implementation.</li>
            <li>It needed to be easy to understand and utilize for a new developer or designer.</li>
            <li>It needed to provide tools to write responsive, modern, efficient site code.</li>
        </ul>

        <p>As a starting point, a tried-and-true solution for most front-end standardization is Bootstrap. Decompiled, Bootstrap is highly modular. Its CSS is built from <abbr title="Leaner CSS" className="initialism">LESS</abbr> files, separated by component! Its JavaScript is also modularized and easy to understand. With some thought, trial, and error, we were able to implement Bootstrap as a true configurable dependency. Each site in the system could access Bootstrap&rsquo;s libraries individually. Devs could bring in components as they saw fit. What bloat?</p>


        <Spacer></Spacer>

        <h2>Moving mountains</h2>

        <p>With the implementation of Bootstrap came an array of pre-made JavaScript tools to do common tasks like displaying modals (you&rsquo;re using one now) and carousels. These functions gave us a bit of inspiration. We knew the features we had to develop and account for constantly - why not abstract and modularize them? Naturally, they all accounted for fallbacks when JavaScript would not be available.</p>

        <p>Here&rsquo;s the short list of the features we developed for:</p>

        <ul>
            <li>Smoothly scrolling through pages automatically or programmatically by detecting links referencing bookmarks.</li>
            <li>Detecting which breakpoint is active relative to Bootstrap&rsquo;s responsive breakpoints.</li>
            <li>Detecting mobile browsers.</li>
            <li>Detecting if an element is visible within the viewport.</li>
            <li>Automatically configuring and caching data to be loaded into modals by path or programmatically.</li>
        </ul>

        <p>The complexity came in when considering the loan applications that would be present on most sites within the system. We needed to account for a variety of common but exhaustive requirements:</p>

        <ul>
            <li>Validation constraints</li>
            <li>Visual validation feedback</li>
            <li>Error messaging</li>
            <li>Handling server-side validation</li>
            <li>Field masking</li>
            <li>Entering dates</li>
            <li>Handling sensitive information</li>
            <li>Keeping fields in view</li>
        </ul>

        <p>Bootstrap comes with configurable, state-specific classes to be used on fields. It&rsquo;s a good start for modularity. It needed to be tied to a framework, though, to become dynamic and functional, leading to deep integration with Parsley.js.</p>

        <ContentFigure
            src="images/framework/framework-moneymutual-validation.jpg"
            alt="MoneyMutual.com field validation states"
            caption="MoneyMutual.com&reg; field validation states"
        ></ContentFigure>

        <p>Our implementation of validation would tie together field requirements between the front and back end, removing redundancy in validation code. It would also allow for custom validation messages to be written depending on the validation error triggered.</p>

        <ContentFigure
            src="images/framework/framework-network-validation.jpg"
            alt="MyCashResource.com field validation states"
            caption="MyCashResource.com field validation states"
        ></ContentFigure>


        <Spacer></Spacer>

        <h2>Finally, Training</h2>

        <p>An opportunity with the implementation of Bootstrap presented itself. The front-end devs had little to no experience using design frameworks, JavaScript, or any standardization. Bootstrap ships with decompiled <abbr title="Leaner CSS" className="initialism">LESS</abbr> using BEM object-oriented naming conventions. I jumped on the chance to start training sessions to get the team up to speed using the new framework as an example to work from and towards. It took sweat, tears, and a bit of arm twisting but we were able to start moving out of the team&rsquo;s comfort zone and into writing more efficient, readable, and reusable site code.</p>

      </Section>
    )
  }
}

export default Framework
