import React, { Component } from 'react'
import Spacer from '../../../Layout/Spacer'
import Section from '../../../Layout/Section'
import ContentFigure from '../../../Layout/ContentFigure'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export class Photography extends Component {
  render() {
    return (
      <Section>

        <h1>Photography and&nbsp;Storytelling</h1>

        <Spacer></Spacer>

        <p>Design philosophies and processes are universal.</p>

        <p>Design is crafting an experience with intention. It&rsquo;s understanding the best way to deliver a message, to tell a story, to get something done.</p>

        <p>I design for the web by craft, but I've practiced similar processes through photography. I&rsquo;ve learned people connect to any art, especially photography, through storytelling.</p>

        <p>I invite you to explore my day-to-day stories, travel stories, through my photography. Experience these moments with me on the following social media outlets:</p>

        <Spacer sm></Spacer>

        <Container>
          <Row>

            <Col md={6} xs={12}>

              <a href="https://ardie-does.tumblr.com" target="_blank" className="btn btn-outline-primary btn-block" rel="noopener noreferrer">Ardie-does on Tumblr</a>

              <Spacer sm className="d-block d-sm-none"></Spacer>
              
            </Col>

            <Col md={6} xs={12}>
              
              <a href="https://instagram.com/ardiedoes" target="_blank" className="btn btn-outline-primary btn-block" rel="noopener noreferrer">ArdieDoes on Instagram</a>

            </Col>

          </Row>
        </Container>

        <Spacer sm></Spacer>

        <ContentFigure
            src="images/photography/photography-city.jpg"
            alt="Sunset over Tokyo"
            caption="Sunset over Tokyo"
        ></ContentFigure>

        <ContentFigure
            src="images/photography/photography-temple1.jpg"
            alt="Stairs lined with autumn leaves leading to a pagoda"
            caption="Autumn leaves along the path to the pagoda"
        ></ContentFigure>

        <ContentFigure
            src="images/photography/photography-countryside.jpg"
            alt="Sunset in the tea fields"
            caption="Sunset in the tea fields"
        ></ContentFigure>

        <ContentFigure
            src="images/photography/photography-temple2.jpg"
            alt="View of the autumn leaves from Kiyomizu-dera"
            caption="Autumn leaves seen from Kiyomizu-dera"
        ></ContentFigure>

      </Section>
    )
  }
}

export default Photography
